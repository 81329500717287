import React, { Fragment } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  Typography,
  makeStyles,
  Divider,
} from "@material-ui/core";
import { domainSis } from "configurations/constant";

const useStyles = makeStyles((theme) => ({
  large: {
    width: "300px",
    height: "300px",
  },
  welcome: {
    fontSize: "20px",
    textAlign: "center",
    fontWeight: "lighter",
  },
  newVersion: {
    fontSize: "16px",
    textAlign: "center",
    fontWeight: "bold",
  },
  legend: {
    textAlign: "center",
  },
  button: {
    textAlign: "center",
  },
}));

const MsgWelcome = ({ openMsm, handleCloseMsm }) => {
  const classes = useStyles();
  const logo = window.location.hostname.includes(domainSis) ? "../oYmSinFondo.png" : "../emagic.png";
  return (
    <Fragment>
      <Dialog fullWidth maxWidth="sm" open={openMsm} onClose={handleCloseMsm} disableBackdropClick >
        <DialogContent >
          <Grid
            container
            justify="center"
            align="center"
            direction="row"
            spacing={2}
          >
            <a target="_blank" href="https://api.whatsapp.com/send?phone=593997839293&text=Hola%20me%20interesa%20la%20informacion">
              <img
              src={logo}
              alt="logo"
              className={classes.large}
            /></a>
          </Grid>
          <br></br>
          <Divider></Divider>
          <br></br>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
          >

            <Grid item md={12}>
              <Typography>
                Estimad@ usuario por ser un cliente fiel, tenemos un beneficio increible para ti, te otorgamos hasta $1500 de entrada para un auto nuevo, da click en la imagen.
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={2}
            ></Grid>

            <Grid item md={12} className={classes.button}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleCloseMsm}
              >
                Cerrar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default MsgWelcome;
